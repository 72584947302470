<template>
  <footer
    class="py-16 sm:py-[90px] mt-32 dark:bg-gradient-core"
    :class="bgColor"
  >
    <div class="max-w-[930px] mx-auto px-5 xl:px-0">
      <template v-if="!authenticated">
        <div class="text-center mb-20">
          <div
            class="p-1.5 rounded-lg bg-gradient-white dark:bg-gradient-core w-10 h-10 mx-auto"
          >
            <svg
              class="block mx-auto"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="PaperPlaneTilt">
                <path
                  id="Vector"
                  d="M23.5008 5.00325C23.5008 5.00325 23.5008 5.0134 23.5008 5.01848L17.5899 24.5124C17.5004 24.829 17.3162 25.1107 17.0621 25.3196C16.8079 25.5286 16.4959 25.6548 16.168 25.6814C16.1213 25.6854 16.0745 25.6875 16.0278 25.6875C15.7205 25.6884 15.4193 25.6014 15.1598 25.4368C14.9003 25.2721 14.6933 25.0366 14.5633 24.7582L10.9365 17.1014C10.9007 17.0256 10.8892 16.9407 10.9035 16.8581C10.9179 16.7756 10.9574 16.6995 11.0167 16.6403L16.8322 10.8238C16.9781 10.6702 17.0583 10.4656 17.0556 10.2538C17.0529 10.0419 16.9675 9.83947 16.8177 9.68964C16.6679 9.53981 16.4654 9.45444 16.2536 9.45173C16.0417 9.44902 15.8371 9.52918 15.6835 9.67512L9.85892 15.4906C9.79972 15.5499 9.72363 15.5894 9.64109 15.6038C9.55854 15.6181 9.47357 15.6066 9.39782 15.5708L1.78064 11.9623C1.48148 11.8252 1.22972 11.6025 1.05724 11.3223C0.88476 11.042 0.799317 10.7169 0.811731 10.3881C0.828273 10.0501 0.950627 9.72591 1.16149 9.46128C1.37236 9.19665 1.66107 9.00499 1.98681 8.9134L21.4807 3.00246H21.496C21.7735 2.92449 22.0668 2.92175 22.3458 2.99454C22.6247 3.06733 22.8793 3.21303 23.0834 3.41668C23.2874 3.62033 23.4336 3.87461 23.507 4.15342C23.5803 4.43223 23.5782 4.72553 23.5008 5.00325Z"
                  fill="#275DEA"
                />
              </g>
            </svg>
          </div>
          <h4 class="mb-8 mt-2 text-3xl sm:text-5xl font-semibold">
            Build your form on AirTable now
          </h4>
          <v-button
            class="inline-block group"
            :to="{ name: 'register' }"
          >
            Get started for free
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 inline -mt-[2px] transition-transform group-hover:translate-x-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </v-button>
        </div>
        <div class="bg-[#454545] h-[1px]" />
      </template>
      <div
        class="md:flex justify-between"
        :class="{ 'mt-[88px]': !authenticated }"
      >
        <div class="w-44 mb-5">
          <NuxtLink
            :to="{ name: authenticated ? 'home' : 'index' }"
            class="flex-shrink-0 font-semibold flex items-center"
          >
            <img
              src="/img/logo.svg"
              alt="airtable tools logo"
              class="w-8 h-8"
            >
            <span
              class="ml-3 text-xl hidden sm:inline text-blue-600 dark:text-white bg-clip-text"
            >
              AirFormTable
            </span>
          </NuxtLink>
          <p class="text-sm text-grey mt-2">
            Build beautiful advanced forms in AirTable
          </p>
        </div>
        <div class="space-y-4 mb-5">
          <h6 class="dark:text-white text-sm font-bold">
            Resources
          </h6>
          <ul class="mt-2 space-y-2">
            <li>
              <NuxtLink
                :to="{ name: 'pricing' }"
                class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue"
              >
                Pricing
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="{ name: 'features' }"
                class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue"
              >
                Features
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="space-y-4 mb-5">
          <h6 class="dark:text-white text-sm font-bold">
            Legal
          </h6>
          <ul class="mt-2 space-y-2">
            <li>
              <NuxtLink
                :to="{ name: 'privacy-policy' }"
                class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue"
              >
                Privacy Policy
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="{ name: 'terms-conditions' }"
                class="text-gray-600 dark:text-gray-400 transition-colors duration-300 hover:text-nt-blue"
              >
                Terms & Conditions
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <p class="text-grey text-sm text-center mt-14 sm:mt-20">
        © Copyright 2024 AirFormTable. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script setup>
import airtableformsConfig from "~/airtableforms.config"
import { computed } from "vue"
const config = airtableformsConfig
defineProps({
  bgColor: {
    type: String,
    default: "bg-gray-50",
  },
})
const authenticated = computed(() => useAuthStore().check)
</script>
